<template>
  <div class="user-manage">
    <table-search :total="total" :conditions="conditions" @searchRes="searchRes" @refresh="getList">
    </table-search>
    <div class="table">
      <el-table
        :data="tableData"
        border
        @filter-change="filterChange"
        @sort-change="sortChange"
        style="width: 100%">
        <el-table-column
          prop="caseId"
          sortable="custom"
          label="求助ID">
        </el-table-column>
        <el-table-column
          prop="caseAccountId"
          label="用户ID">
        </el-table-column>
        <el-table-column
          prop="publisherName"
          label="真实姓名">
        </el-table-column>
        <el-table-column
          prop="caseMainType"
          :filters="applyFilter"
          column-key="caseMainType"
          label="求助类型">
          <template slot-scope="scope">
            <span style="color:#409EFF;" v-if="scope.row.caseMainType==1">诉讼业务</span>
            <span style="color:#67C23A;" v-else-if="scope.row.caseMainType==2">非诉讼业务</span>
            <div>{{scope.row.caseTypeName}}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="deadline"
          width="225"
          label="时间信息">
          <template slot-scope="scope">
            <div>发布时间：{{scope.row.createTime}}</div>
            <div>投标截止：{{scope.row.deadline}}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="viewCount"
          sortable="custom"
          label="浏览量">
        </el-table-column>
        <el-table-column
          prop="followCount"
          sortable="custom"
          label="关注数">
        </el-table-column>
        <el-table-column
          prop="submitCount"
          sortable="custom"
          label="报名数">
        </el-table-column>
        <el-table-column
          prop="status"
          :filters="typeFilter"
          column-key="status"
          label="求助阶段">
          <template slot-scope="scope">
            <span>{{targetStatus && targetStatus[scope.row.status]}}</span>
          </template>
        </el-table-column>
        
        <el-table-column
          width="160"
          prop="val"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="checkHandle(scope.row)">求助详情</el-button>
            <el-button type="text" v-show="scope.row.showInvalidButtonFlag" @click="invalidHandle(scope.row)">废标操作</el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" @click="userHandle(scope.row)">操作日志</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer flex-row">
      <div class="page">
        <el-pagination
          background
          :page-size="form.perPage"
          :current-page="form.page"
          @size-change="handleSizeChange"
          layout="prev, pager, next,sizes,jumper"
          :page-sizes="[10, 20, 30, 40]"
          @current-change="currentChange"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <user-dynamic ref="userDynamic" title="操作日志" :info="dynamicInfo"></user-dynamic>
    <seek-help-dialog ref="seekHelpDialog" :typeFlag="false" :info="info"></seek-help-dialog>
  </div>
</template>

<script>
import TableSearch from '../../components/table-search.vue'
import UserDynamic from '../../components/dialog/user-dynamic.vue'
import SeekHelpDialog from '../verify-manage/coms/seek-help-dialog.vue'
import {helpManageList,caseDetail,caseOperateRecordList,invalid} from '../../api/verify'
import {mapState} from 'vuex'
export default {
  name:'seekhelp',
  data () {
    return {
      total:0,
      info:{},
      dynamicInfo:[],
     form:{
        page:1,
        perPage:10,
        sortWay:'',
        businessType:1,
        caseMainType:'',
        statusPhase:''
      },
      conditions:[
        {value:'caseId',label:'求助ID'},
        {value:'accountId',label:'用户ID'},
        {value:'publisherName',label:'真实姓名'},
      ],
      tableData:[
        {
          val:'1',
          id:1,
          status:'1'
        },
        {
          val:'2',
          id:2,
          status:'2'
        },
        {
          val:'3',
          id:3,
          status:'3'
        },
        {
          val:'4',
          id:4,
          status:'4'
        }
      ],
      multipleSelection:[],
        statusFilter:[
          {text: '正常', value: '1'}, 
          {text: '禁言', value: '2'}, 
          {text: '禁止登陆', value: '3'}
        ],
        applyFilter:[
          {text: '诉讼业务', value: 1}, 
          {text: '非诉讼业务', value: 2}
        ],

    };
  },
  components: {
    TableSearch,
    UserDynamic,
    SeekHelpDialog
  },
  computed:{
    ...mapState({
      targetStatus: state => state.dictionary.targetStatus
    }),
    typeFilter:function(){
      let res = [];
      for (let key in this.targetStatus){
        res.push({
          value:key,
          text:this.targetStatus[key]
        })
      }
      return res;
    }
  },
  methods: {
    getDetail(caseId){
      caseDetail({caseId:caseId}).then(res=>{
        let data =res.data;
        if(data.code ==='000'){
          this.info = data.content;
        }else{
          console.log(data.desc)
        }
      })
    },
    searchRes(para){
      this.form = Object.assign({
        page:1,
        perPage:this.form.perPage,
        sortWay:this.form.sortWay,
        businessType:this.form.businessType,
        caseMainType:this.form.caseMainType,
        statusPhase:this.form.statusPhase
        },para);
      this.getList();
    },
    getList(){
      helpManageList(this.form).then(res=>{
        let data =res.data;
        if(data.code ==='000'){
          this.tableData = data.content.list;
          this.total = data.content.total;
        }else{
          console.log(data.desc)
        }
      })
    },
    // 分页
    currentChange(val){
      this.form.page = val;
      this.getList();
    },
    handleSizeChange(val){
      this.form.perPage = val;
      this.form.page = 1;
      this.getList();
    },
    addHandle(){},
    sortChange(sort){
      if(sort.prop =='caseId'){
        this.form.sortWay = sort.order==='ascending'?1:2;
      }
      if(sort.prop =="viewCount"){
        this.form.sortWay = sort.order==='ascending'?7:8;
      }
      if(sort.prop =="followCount"){
        this.form.sortWay = sort.order==='ascending'?9:10;
      }
      if(sort.prop =="submitCount"){
        this.form.sortWay = sort.order==='ascending'?11:12;
      }
      this.getList();
    },
    invalidHandle(row){
      this.$confirm('此操作将申请废标, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          invalid({caseId:row.caseId}).then(res=>{
            let data =res.data;
            if(data.code ==='000'){
              this.$message.success(data.desc);
              this.getList();
            }else{
              this.$message.warning(data.desc);
            }
          })
        })
    },
    checkHandle(row){
      this.$refs.seekHelpDialog.dialogVisible = true;
      this.getDetail(row.caseId);
    },
    userHandle(row){
      caseOperateRecordList({caseId:row.caseId}).then(res=>{
        let data =res.data;
        if(data.code ==='000'){
          this.dynamicInfo = data.content.list;
        }else{
          console.log(data.desc)
        }
      })
      this.$refs.userDynamic.dialogVisible = true;
    },
    editHandle(row){},
    forbidAll(){},
    forbid(){},
    filterChange(filters){
      if(filters.caseMainType){
        this.form.caseMainType = filters.caseMainType.join();
      }
      if(filters.status){
        this.form.statusPhase = filters.status.join();
      }
      this.form.page = 1;
      this.getList();
    }
  },
   created(){
    this.getList();
  }
}

</script>
<style lang='scss' scoped>
.footer{
  justify-content: flex-end;
  margin:15px 0;
}
.dot{
  font-size: 20px;
  padding-right: 5px;
  vertical-align:middle;
}
</style>