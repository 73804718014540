<template lang="html">
<el-dialog
  title="求助信息"
  :visible.sync="dialogVisible"
  width="50%">
  <div>
    <el-descriptions title="">
        <el-descriptions-item label="具体描述" :span="2" :labelStyle="{whiteSpace:'nowrap'}">
          {{info.description}}
        </el-descriptions-item>
        <el-descriptions-item label="求助状态" >{{targetStatus[info.status]}}</el-descriptions-item>
        <el-descriptions-item label="求助ID">{{info.caseId}}</el-descriptions-item>
        <el-descriptions-item label="发布人"> {{info.publisherName}}  </el-descriptions-item>
        <el-descriptions-item label="发布时间"> {{info.createTime}}  </el-descriptions-item>
        <el-descriptions-item label="求助类型"> {{info.caseMainType == 1?'诉讼业务':'非诉讼业务'}} - {{info.caseTypeName}}   </el-descriptions-item>
        <el-descriptions-item label="我的身份">{{identity[info.identity]}}</el-descriptions-item>
        <el-descriptions-item label="涉案金额">{{info.amount}} 元</el-descriptions-item>
        <el-descriptions-item label="受理法院">{{info.court}}</el-descriptions-item>
        <el-descriptions-item label="法院所在区域">{{info.provinceName}}&nbsp;{{info.cityName}}&nbsp;{{info.areaName}}</el-descriptions-item>
        <el-descriptions-item label="报名截止">{{info.deadline}}</el-descriptions-item>
        <el-descriptions-item label="联系姓名">{{info.contactPersonName}}</el-descriptions-item>
        <el-descriptions-item label="联系手机">{{info.contactPhoneNumber}}</el-descriptions-item>
        <el-descriptions-item label="提交保证金">{{info.depositAmount}}</el-descriptions-item>
        <el-descriptions-item label="保证金订单号">{{info.depositOrderNo}}</el-descriptions-item>
        
    </el-descriptions>


    <el-descriptions title="相关文件" :colon="false">
      <el-descriptions-item :span="3" :labelStyle="{marginRight:0}" :key="index" v-for="(item,index) in info.caseFileList">
				<a :href="item.fileUrl" target="_blank" download style="color:#409EFF">{{item.fileName}}</a>
			</el-descriptions-item>
    </el-descriptions>
    <div v-if="!info.caseFileList || info.caseFileList.length==0">暂无</div>
    <div class="el-descriptions" v-if="typeFlag">
      <div class="el-descriptions__header">
        <div class="el-descriptions__title">审核操作</div>
      </div>
    </div>
    
    <el-form ref="form" :model="form" label-width="84px" v-if="typeFlag">
      <el-radio-group v-model="form.verifyResult">
          <el-radio label="4">通过审核</el-radio>
		      <el-radio label="3">未通过审核</el-radio>
		      <el-radio label="2">等待审核</el-radio>
        </el-radio-group>
      <el-form-item label="未通过原因" style="margin-top:12px;" v-show="form.verifyResult == 3">
        <el-input type="textarea" :rows="1" v-model="form.comments" style="width:300px;"></el-input>
      </el-form-item>
    </el-form>

  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="cancel">关 闭</el-button>
    <el-button type="primary" @click="ensure" v-if="typeFlag">提交审核结果</el-button>
  </span>
</el-dialog>
</template>

<script>
import {mapState} from 'vuex'
    export default {
      data(){
        return{
          dialogVisible:false,
          form:{
            verifyResult:'2',
            comments:''
          }
        }
      },
      props:{
        typeFlag:{
          default:true,
          type:Boolean
        },
        info:{
          default:function(){
            return {}
          },
          type:Object
        }
      },
      computed:{
        ...mapState({
          targetStatus : state => state.dictionary.targetStatus,
          identity : state => state.dictionary.identity,
          winMode : state => state.dictionary.winMode,
          showAuthority : state => state.dictionary.showAuthority,
        })
      },
      components:{},
      methods:{
        cancel(){
          this.form.comments = '';
          this.form.verifyResult = '2';
    			this.dialogVisible = false;
        },
        ensure(){
          this.dialogVisible = false;
          if(this.form.verifyResult ==2){
            return;
          }
          this.$emit('ensure',this.form);
        }
      }
    }
</script>

<style scoped lang="scss">
</style>